<template>
    <div class="test__auth">
        <div class="title">
            <h2>Test auth</h2>
        </div>
        <div class="field__cont">
            <div class="field">
                <input type="text" v-model="userUid" placeholder="User uid">
            </div>
            <div class="user__templates field">
                <div class="user"
                 v-for="user,index in users" 
                 :key="`user__${index}`"
                 v-bind:class="{
                    active : choisedUser == user.name
                 }"
                 @click="() => {
                    selectUser(user)
                 }"
                 >
                    {{user.name}}
                </div>
            </div>
            <div class="field" v-if="access">
                <p>Access token</p>
                <textarea v-model="access" cols="30" rows="4" readonly></textarea>
            </div>
            <div class="field" v-if="refresh">
                <p>Refresh token</p>
                <textarea v-model="refresh" cols="30" rows="4" readonly></textarea>
            </div>

        </div>
        <div class="button" @click="entry">
            Войти
        </div>
        <div class="to__service" @click="toService">
            Go to calendar list
        </div>
    </div>
</template>

<script>
import router from '../../router'
import AuthService from '../../service/auth-service'

export default {
    name: 'AuthTest',
    data() {
        return {
            users: [
                {name: "Timur", uid: "115760509214955403147"},
                {name: "Robert", uid: "robert.egoro.v@gmail.com"},
            ],
            choisedUser: "",
            userUid: "",
            access: "",
            refresh: "",
        }
    },
    methods: {
        selectUser({name, uid}){
            if (this.choisedUser && this.choisedUser == name){
                this.choisedUser = ""
                this.userUid = ""
                return
            } 

            this.choisedUser = name
            this.userUid = uid
        },
        entry(){
            // If userUid is empty
            if (!this.userUid){
                return
            }

            let userAuthData = {
                "userUid": this.userUid,
                "authMethod": 1,
            }

            AuthService.signIn(userAuthData)
            .then( response => {
                let data = response.data
                this.refresh = data.refreshToken
                this.access = data.accessToken
                this.$store.dispatch("setup-geo")
                this.$store.dispatch('update-user-data')
            })
        },
        toService(){
            router.replace({name:"calendar-list" , replace: true })
        }
    },
}
</script>

<style scoped>
.test__auth{
    padding: 40px;
}

.title{
    margin-bottom: 20px;
}

.user__templates{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 16px;
}
.user{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 34px;
    border: 2px solid lightgray;
    border-radius: 7px;
    user-select: none;
}

.user.active{
    border-color: rgb(10, 127, 204);
}

.field{
    margin-bottom: 16px;
}

input, textarea{
    border: 2px solid lightgray;
    padding: 9px;
    border-radius: 7px;
    width: calc(100% - 22px);
}

textarea{
    resize: none;
}

.button{
    background: rgb(10, 127, 204);
    border-radius: 7px;
    display: flex;
    justify-content:center;
    align-items: center;
    height: 40px;
    font-weight: 600;
    color: white;
    user-select: none;
}
.to__service{
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(10, 127, 204);
    text-decoration: underline;
    user-select: none;
}
</style>